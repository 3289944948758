<template class="hyundai">
  <header class="h-header py-1 border-bottom hyu-background shadow-sm " z-index="5">

    <div class="container-fluid d-grid gap-3 align-items-center" style="grid-template-columns: 1fr 2fr;">

      <div class="dropdown d-flex align-items-left">
        <div v-if="showMenu"
          class=" collapse-btn icon-toggle d-flex align-items-center col-lg-1 mb-2 mb-lg-0 link-dark text-decoration-none ">
          <div @click="toggleMenu = !toggleMenu">
            <img class=" align-self-center mx-2 my-1" src="./assets/images/burger.png" width="24" height="24">
          </div>
        </div>
        <div class=" collapse-btn logo d-flex align-items-center col-lg-5 mb-2 mb-lg-0 link-dark text-decoration-none ">
          <img @click="logout" class=" align-self-center mx-2 my-1" src="./assets/images/Hyundai_Logo_Blue.png" width=167
            height="28">
        </div>
        <form v-if="logedUser === 'true'" @submit.prevent="onSubmit" @keydown.enter.prevent="onEnter" class="w-10 ">
          <input v-model="searchTerm" type="search" class="form-control" placeholder="Search..." aria-label="Search">
        </form>

      </div>

      <div v-if="logedUser === 'true'" class="d-flex flex-row-reverse align-items-right">
        <div class=" dropdown ">
          <a href="#"
            class="d-flex align-items-center col-lg-4 mb-2 mb-lg-0 link-dark text-decoration-none dropdown-toggle"
            id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
            <img class=" align-self-center mx-1 my-1" src="./assets/images/User_Icon.png" width="24" height="24">
          </a>
          <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
            <li><a class="dropdown-item" href="#">{{ Username }}</a></li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><a v-on:click="logout" class="dropdown-item" href="#">Sign out</a></li>
          </ul>
        </div>
      </div>

    </div>
  </header>
  <div class='content-body'>

    <div v-if="Userstate === 1" class="row">
      <div class="col-2" v-show="toggleMenu">
        <sidebar />
      </div>

      <div :class="toggleMenu ? 'col-10' : 'col-12'">
        <router-view />
        <div class="footer-div">
          <p class='footer-copy'>© Motus. All rights reserved.</p>
          <transition name="toast">

          </transition>
        </div>
      </div>
    </div>

    <div class="row" v-if="logedUser == null || logedUser == false">
      <router-view />
    </div>





  </div>
</template>

<script>
import sidebar from '@/components/sideBar.vue'


export default {
  data() {
    return {
      toggleMenu: true,
      menuVisible: window.localStorage.getItem("logedin"),
      logedUser: window.localStorage.getItem('logedin'),
      searchText: ''

    };
  },
  components: {
    sidebar,

  },
  watch: {
    $route() {
      window.scrollTo(0, 0)
    }
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.searchInput
      },
      set(value) {
        this.$store.commit('setSearchInput', value)
      }
    },
    showMenu() {
      let logged = window.localStorage.getItem('logedin')
      if (logged === null) {
        console.log("Dont Show Yo")
        return false
      } else {
        return true
      }
    },
    Userstate() {

      if (window.localStorage.getItem("user") === null) {
        //window.localStorage.setItem("logedin",false)
        return 0
      }
      else {
        return JSON.parse(window.localStorage.getItem("user")).accountStatus;
      }

    },

    Username() {
      return JSON.parse(window.localStorage.getItem("user")).userName;
    },



  },
  methods: {

    get_login: function () {
      return window.localStorage.getItem('logedin');
    },

    logout: function () {
      window.localStorage.clear();
      window.location = '/';
    },
  },
}
</script>

<style module>
.icon-toggle {
  cursor: pointer;
}

.content-body {
  margin-top: 38px !important;

}
.custom-table{
  width: 100%;
}
.custom-table > tbody > tr > td {
    padding: 1rem 1rem !important; /* Change padding */
    color: inherit !important; /* Change text color */
    background-color: inherit !important; /* Change background color */
    border-bottom-width: inherit !important; /* Change border width */
    box-shadow: none !important; /* Change box shadow */
}

.container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

}
</style>

<style>
.hyundai {
  font-family: "Hyundai Sans Text Office";
}


.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}


.footer-copy {
  color: #002C5F;
  margin-top: 1rem;
}

.h-header {
  font-family: "Hyundai Sans Head Office";
  position: fixed;
  left: 0;
  right: 0%;
  top: 0;
  z-index: 10;
}

.collapse-btn {
  cursor: pointer;
}

.logo {
  min-width: 184px;
}

.c {
  background: #E4DCD3;
}

element {
  z-index: 5;
}


@font-face {
  font-family: 'Hyundai Sans Text Office';
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local('Hyundai Sans Text'), url('~@/assets/fonts/Hyundai Sans/HyundaiSansTextOffice-Regular.woff2') format('woff2'), url('~@/assets/fonts/Hyundai Sans/HyundaiSansTextOffice-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Hyundai Sans Head Office';
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local('Hyundai Sans Head'), url('~@/assets/fonts/Hyundai Sans/HyundaiSansHeadOffice-Regular.woff2') format('woff2'), url('~@/assets/fonts/Hyundai Sans/HyundaiSansHeadOffice-Regular.woff') format('woff');
}

.body {
  font-family: "Hyundai Sans Text Office" !important;

}

#app {
  font-family: 'Hyundai Sans Text Office', 'Hyundai Sans Head Office', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.dropdown-menu.show {
  transform: translate(12px, 40px) !important;
  border: none;
}

.footer-div {
  height: 5vh;

}

.toast-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}

.toast-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.toast-enter-active {
  transition: all 0.3s ease;

}

.toast-leave-from {
  opacity: 1;
  transform: translateY(0px);
}

.toast-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}

.toast-leave-active {
  transition: all 5s ease;

}
</style>
