<template>
  <div class="login">
    <img
        class="hyu-background-image col col-12 shadow-sm"
        src="../assets/images/hyundai_banner.png"
        alt="Hyundai Logo "

    />
    <div>
      <div
          class="modal modal-signin position-static col col-12 d-block bg-white"
          tabindex="-1"
          role="dialog"
          id="modalSignin"
      >
        <div class="modal-dialog col align-self-center" role="document">
          <div class="main-content shadow-sm hyu-background">
            <!-- Logo Section -->
            <div class="modal-header row">
              <div class="col col-3"></div>
              <div class="col col-6">
                <img
                    class="hyu-logo-image align-self-center mt-4"
                    src="../assets/images/Hyundai_Logo_Blue_big.png"
                    alt="Hyundai Logo"
                />
              </div>
              <div class="col col-3"></div>

            </div>



            <hr class="my-3 breaker-bar"/>
            <!-- Sign In Section -->
            <div class="modal-body px-4 pb-4 pt-0">
              <h2 class="fs-5 fw-bold mb-2">Sign In</h2>
              <small class="text-muted"
              >Enter your email address and password to access the
                system.</small
              >
              <form class="my-3" @submit="submitLogin">
                <div class="ml-auto justify-content-start col col-12  mb-3">
                  <input
                      v-model="email"
                      type="email"
                      class="input-spec form-control rounded-0"

                      placeholder="Email Address*"
                  />

                </div>
                <div class="ml-auto justify-content-start col col-12  mb-2">
                  <input
                      v-model="password"
                      type="password"
                      class="input-spec form-control rounded-0"
                      id="floatingPassword"
                      placeholder="Password*"
                  />

                </div>
                <div class="justify-content-between row px-1">
                  <a @click="regInactive =!regInactive"  type="link" class="col-4 text-start" style="cursor:pointer;"
                  >Need To Register</a
                  >
                  <a href="/forgot-password" type="link" class="col-4 text-end "
                  >Forgot Your Password</a
                  >
                </div>
                <button

                    class="btn_hyundai button-text w-100 mt-2 mb-2 btn btn-lg "
                    type="submit"
                    v-if="!loader"
                >
                  SIGN IN
                </button>

                <!-- Register In Section -->

              </form>
              <Feedback ref="feedback"/>
              <form v-if="!regInactive" @submit="submitRegister">
                <hr class="my-3"/>
                <h2 class="fs-5 fw-bold mb-3">Not Registered Yet?</h2>
                <small class="text-muted">
                  Enter your email address and we’ll send you an email confirmation with registration details..
                </small
                >
                <div class="ml-auto justify-content-start col col-12  mt-3 mb-2">
                  <input
                      v-model="regEmail"
                      type="email"
                      class=" input-spec form-control rounded-0"
                      id="floatingInput"
                      placeholder="Email Address*"
                      v-if="!loader"
                  />

                </div>
                <button

                    class="btn_hyundai button-text w-100 py-2 btn btn-outline-secondary rounded-4"
                    type="submit"
                    v-if="!loader"
                >

                  REGISTER
                </button>

              </form>
              <div class="ml-auto justify-content-start col col-12 px-5 ">
                <div v-show="loader" class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Begin -->
  <div @change="loader = !loader" class="  modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="rp-password modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class=" fw-bold  p-3 modal-title" id="staticBackdropLabel">NOTICE</h5>

            <Feedback ref="feedback"/>

          <h6>{{theMessage}}</h6>

        </div>
        <div class="modal-footer ms-auto mx-auto">
          <button  type="button" class="type-btn btn btn_hyundai "  data-bs-dismiss="modal" >OK</button>
<!--          onclick="window.location.href='/'"-->
        </div>
      </div>
    </div>
  </div>
  <!-- Modal End -->
  <!-- Modal Begin -->
  <div @change="loader = !loader" class="  modal fade" id="staticError" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="rp-password modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class=" fw-bold  p-3 modal-title" id="staticBackdropLabel">NOTICE</h5>

          <Feedback ref="feedback"/>
          <h6>{{theMessage}}</h6>

        </div>
        <div class="modal-footer ms-auto mx-auto">
          <button  type="button" class="type-btn btn btn_hyundai "  data-bs-dismiss="modal" >OK</button>
          <!--          onclick="window.location.href='/'"-->
        </div>
      </div>
    </div>
  </div>
  <!-- Modal End -->
</template>

<script>
//import { mapActions } from 'vuex';
import auth from "../api.js";
import core from "../core.js";
import Feedback from "@/views/FeedbackScreen";
import {Modal} from "bootstrap";

export default {
  data() {

    return {
      theMessage: '',
      logCounter: 0,
      menuVisible: false,
      email: null,
      password: null,
      regEmail: null,
      regInactive: true,
      loader: false,
      myModal: null,
    };
  },
  created() {
    window.localStorage.clear();

  },
  methods: {
    showModal(){
      this.myModal = new Modal(document.getElementById('staticBackdrop'));
      this.myModal.show();
    },
    showErrorModal(){
      this.myModal = new Modal(document.getElementById('staticError'));
      this.myModal.show();
    },
    submitLogin(e) {
      e.preventDefault();
      this.loader = true;
      const error = (msg) => {
        localStorage.clear();
        console.log("Login-Error");
        console.error(msg);
        core.giveUserFeedback(true, msg);
        this.theMessage = msg;
        this.$refs.feedback.update();
        this.loader = false;
        this.showModal();
      };

      const success = (response) => {
        debugger
        console.log("Login Success");    
        core.giveUserFeedback(false, response.message);
        this.loader = false;
        this.$refs.feedback.update();
        window.location = "/users";
      };

      auth.login(this.email, this.password, success, error);
    },

    submitRegister(e) {
      let that = this
      that.loader = true
      e.preventDefault();

      let error = function (msg) {
        console.error(msg);
        core.giveUserFeedback(true, msg);
        that.$refs.feedback.update();
        that.theMessage = "An Error has Occurred Please Try Again Later."
        that.loader = false;
        that.showModal()
      };

      let s = function (msg) {
        core.giveUserFeedback(false, msg);
        that.$refs.feedback.update();
        that.regInactive = true;
        that.theMessage = "Registration Email Has been sent to your supplied email."
        that.loader = false;
        that.showModal()
      };

      auth.startBusinessUserRegistration(this.regEmail, s, error);
    },
  },

  components: {Feedback},
};
</script>

<style>

.rp-password{
  top: 0px !important;
  max-width: 440px !important;
  font-family: "Hyundai Sans Text Office" !important;
}
.button-text {
  font-size: 16px !important;
  text-decoration: none;
  font-family: "Hyundai Sans Text Office";
}

.modal-content{
  background-color: #E4DCD3 !important;
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
}

.type-btn{

  background-color: #002C5F;
  color: #ffffff;
  font-weight: 400;
  font-size: 0.9em;
  width: 100px;
  height: 36px;
  border-radius: 5px;
  padding: 6px 6px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 1em;
}

.login {
  position: relative;
  margin-top: 44px;
}

.hyu-background {
  background: #e4dcd3;
}

.hyu-background-image {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0%;
  z-index: 1;
}

.hyu-logo-image {
  max-height: 32px;
  width: 182px;
}

.modal-dialog {
  max-width: 700px !important;
  z-index: 5;
  top: 100%;
}

.main-content {
  font-family: "Hyundai Sans Text Office";
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #e4dcd3 !important;
  background-clip: padding-box;
  border: none !important;
  border-radius: 9px !important;
  outline: 0;
}

.input-spec {
  font-size: 14px !important;
  font-family: "Hyundai Sans Text Office";

}

.modal-header {
  border-bottom: none !important;
}

#modalSignin {
  padding-top: 5rem;
}

.breaker-bar {
  height: 2px;
  width: 93%;
  margin-right: auto;
  margin-left: auto;
}

.btn_hyundai {
  border-radius: 5px !important;
  font-family: "Hyundai Sans Text Office" !important;
  color: #ffffff !important;
  font-size: 16px !important;
  background: #002c5f !important;
  border: solid #002856 1px !important;
  text-decoration: none !important;
}

.btn_hyundai:hover {
  background: #00AAD2 !important;
  text-decoration: none !important;
  border: solid #00AAD2 1px !important;
}

/*spinner*/

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.lds-ellipsis div {
  position: absolute;
  top: 2rem;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00AAD2;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
