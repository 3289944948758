import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedArea: null,
    searchInput: '',
  },
  mutations: {
    setSelectedArea: (state, payload) => {state.selectedArea = payload},
    setSearchInput: (state, payload) => {state.searchInput = payload},
  },
  actions: {
  },
  modules: {
  }
})
