<template>
  <div  style="height: 100%;min-width: 170px"  >
       <!-- Sidebar -->
    <div class="sidebar hyu-background" id="sidebar-wrapper">
      
      <div class="list-group list-group-flush">
        <div v-if="showAdmin">
          <div class=" mr-auto d-flex align-items-center justify-content-start navbar-toggler sidebar-button grid grid-cols-8  mb-2 p-3"  data-bs-toggle="collapse" data-bs-target="#userNavOptions">
              <img class=" sidebar-icon" src="../assets/images/menu_user.png" />
              <div class=" sidebar-items ml-2 col-span-6">USERS</div>
          </div>
          <div class="collapse sidebar-sub" id="userNavOptions">
            <router-link to="/users" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'users' ? 'sidebar-active' : 'sidebar-inactive'">
              <div class="sidebar-icon"></div>
              <div class=" sidebar-items ml-2 col-span-6">USER DASHBOARD</div>
            </router-link>
            <router-link to="/company-dash" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'company-dash' ? 'sidebar-active' : 'sidebar-inactive'">
              <div class="sidebar-icon"></div>
              <div class=" sidebar-items ml-2 col-span-6">COMPANY DASHBOARD</div>
            </router-link>
            <router-link to="/psd-dash" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'psd-dash' ? 'sidebar-active' : 'sidebar-inactive'">
              <div class="sidebar-icon"></div>
              <div class=" sidebar-items ml-2 col-span-6">PSD DASHBOARD</div>
            </router-link>
            <router-link to="/entity-dash" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'entity-dash' ? 'sidebar-active' : 'sidebar-inactive'">
              <div class="sidebar-icon"></div>
              <div class=" sidebar-items ml-2 col-span-6">ENTITY DASHBOARD</div>
            </router-link>


          </div>
        </div>

        <div class=" mr-auto d-flex align-items-center justify-content-start navbar-toggler sidebar-button grid grid-cols-8  mb-2 p-3"  data-bs-toggle="collapse" data-bs-target="#vehNavOptions"> 
            
              <img class=" sidebar-icon" src="../assets/images/menu_vehicles.png" />
              <div class=" sidebar-items ml-2 col-span-6">VEHICLES</div>
             
        </div>

        <div class="collapse sidebar-sub" id="vehNavOptions">
          <router-link to="/vehicles" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'vehicles' ? 'sidebar-active' : 'sidebar-inactive'"> 
            <div class="sidebar-icon"></div>
            <div class=" sidebar-items ml-2 col-span-6">VEHICLE DASHBOARD</div>
          </router-link>
          <router-link v-if="!showAdmin" to="/quote" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'quote' ? 'sidebar-active' : 'sidebar-inactive'">
            <div class="sidebar-icon"></div>
            <div class=" sidebar-items ml-2 col-span-6">ADD QUOTE</div>
          </router-link>

        </div>


        <div class=" mr-auto d-flex align-items-center justify-content-start navbar-toggler sidebar-button grid grid-cols-8  mb-2 p-3"  data-bs-toggle="collapse" data-bs-target="#repNavOptions">

          <img class=" sidebar-icon" src="../assets/images/menu_reporting.png" />
          <div class=" sidebar-items ml-2 col-span-6">REPORTING</div>

        </div>

        <div class="collapse sidebar-sub" id="repNavOptions">
          <router-link to="/rep-dash" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'rep-dash' ? 'sidebar-active' : 'sidebar-inactive'">
            <div class="sidebar-icon"></div>
            <div class=" sidebar-items ml-2 col-span-6">DASHBOARD</div>
          </router-link>
          <router-link to="/system-reports" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'system-reports' ? 'sidebar-active' : 'sidebar-inactive'">
            <div class="sidebar-icon"></div>
            <div class=" sidebar-items ml-2 col-span-6">SYSTEM REPORTS</div>
          </router-link>
          <router-link to="/system-uploads" class="mr-auto d-flex align-items-center justify-content-start sidebar-button grid grid-cols-8  mb-2 p-3" :class="selectedArea === 'system-uploads' ? 'sidebar-active' : 'sidebar-inactive'">
            <div class="sidebar-icon"></div>
            <div class=" sidebar-items ml-2 col-span-6">SYSTEM UPLOADS</div>
          </router-link>


        </div>
      </div>
    </div>
    <!-- /#sidebar-wrapper --> 
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {

  computed:{
    ...mapState(['selectedArea']),

    userRole() {
      return JSON.stringify(window.localStorage.getItem("user").user).entity.entityLevel;

    },
    showAdmin() {
     if (window.localStorage.getItem("user") === null){
       console.log("YOU ARE NOT LOGGED")
        return false
      }
      else {
        let roleType = JSON.parse(window.localStorage.getItem("user")).entity.entityLevel
        if (roleType === 0) {
          console.log("YOU ARE ADMIN")
          return true
        } else {
          console.log("YOU ARE NOT ADMIN")
          console.log(roleType)
          return false
        }
      }

    },


  },
 
}

</script>

<style>

      .sidebar-icon{
        
        margin-right: 10px;
        height: 30px;
        width: 30px;
        cursor: pointer;

      }
      .hyu-background{
        background: #E4DCD3;
      }

      body {
        overflow-x: hidden;
      }

      .sidebar-button{
        text-align: left;
        

      }

      .sidebar-items{
        font-weight: 700;
        font-size: 0.8rem;
        cursor: pointer;
        
      }

      .sidebar{
        letter-spacing: 0.03rem;
        position: fixed;
        margin-top: 46px;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 17vw;
        min-width: 205px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */


      }
      @media(max-width: 1030px) {
        .sidebar{
          min-width: 0;
        }
      }
      .sidebar-sub{
          
          display: flex;
          flex-direction: column;
          height: 100%;

      }
      
      .sidebar-active{
        color: whitesmoke;
        background-color: #00AAD2;
        text-decoration: none;
        cursor: pointer;
      }

      .sidebar-inactive{
        color: #002C5F;
        background-color: 0;
        text-decoration: 0;
        cursor: pointer;
      }


      #sidebar-wrapper {
         margin-left: -15rem;
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;
      }
      
      
      
      
      
      
      
      #wrapper.toggled #sidebar-wrapper {
        margin-left: 0;
      }
      
      @media (min-width: 768px) {
        #sidebar-wrapper {
          margin-left: 0;
        }
      
        #page-content-wrapper {
          min-width: 0;
          width: 100%;
        }
      
        #wrapper.toggled #sidebar-wrapper {
          margin-left: -15rem;
        }
      }
      
    

</style>