import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSpinner)




/*createApp(App).use(store).use(router).mount('#app')*/

fetch('/config.json')
    .then(res => res.json())
    .then(() => {
        createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).mount('#app')
        /*new Vue({
            data: () => ({
                urlConfig : config
            }),
            router,
            store,
            render: h => h(App)
        }).$mount('#app')*/

    })