<template>
  <div >
    {{ message }}
  </div>
</template>
<script>
export default {
  name: 'FeedbackScreen',
  data() {
    return {
      show: false,
      type: "",
      message: "",
      opacity: 0.0,
      style: "opacity: 100%;"
    };
  },
  created() {
    this.update()
  },
  methods: {
    update() {
      console.log("Updating feedback");
      if (localStorage.getItem("message_isError") !== null && localStorage.getItem("message_content") !== null) {
        let easeInOutQuint = (x) => {
          return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
        }

        const fadeOutDelay = 100;
        let isError = localStorage.getItem("message_isError") === "true" ? true : false;
        this.type = isError ? "alert alert-danger" : "alert alert-success";
        this.show = true;
        this.message = localStorage.getItem("message_content");

        window.localStorage.removeItem("message_isError");
        window.localStorage.removeItem("message_content");

        if (!isError) {
          let fader = () => {
            this.opacity += 0.01;
            this.style = "opacity: " + (100 - ((easeInOutQuint(this.opacity) * 100))) + "%;";

            if (this.opacity < 1) {
              setTimeout(fader, fadeOutDelay);
            } else {
              this.show = false;
            }
          };
          setTimeout(fader, fadeOutDelay);

        }
      }
    }
  }
}
</script>

<style>
.exit-mark{
  position: absolute;
  right: 24px;
  color: black;
  cursor: pointer;
}

.feedback_panel {
  position: fixed !important;
  z-index: 10;
  top: 60px;
  width: 30%;
  left: 43%;
}
</style>
