import axios from 'axios';

// Create an Axios instance with base URL
const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
});

// Interceptor to add Authorization header and guid parameter
instance.interceptors.request.use(config => {
  debugger
  const token = localStorage.getItem('token');
  const guid = localStorage.getItem('guid');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    
  }

  if (guid) {
    // Append guid to the URL
    const url = new URL(config.url, config.baseURL);
    url.searchParams.append('guid', guid);
    config.url = url.toString();
  }

  return config;
}, error => {
  return Promise.reject(error);
});

export default {
  Url: process.env.VUE_APP_API,

  getMicroData(jobID, s, e) {
    instance({
      method: 'get',
      url: `/Microcat/GetJobData?jobId=${jobID}`,
    })
    .then(response => {
      var resp = response.data;
      s(resp);
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  isMicroDone(jobID, s, e) {
    instance({
      method: 'get',
      url: `/Microcat/IsOrderDone?jobId=${jobID}`,
    })
    .then(response => {
      var resp = response.data;
      s(resp);
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  getEntityPermissions(s, e) {
    instance({
      method: 'get',
      url: '/Entity/GetPermissions',
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.body);
      } else {
        e(resp.body);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  getPSDEmail(psd, s, e) {
    let psdNoSpace = psd.replace(/ /g, '%');

    instance({
      method: 'get',
      url: `/Psd/Get?name=${psdNoSpace}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.body);
      } else {
        e(resp.body);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  startBusinessUserRegistration(email, s, e) {
    if (email !== null) {
      instance({
        method: 'get',
        url: `/ApprovalRegistration/StartBusinessUser?email=${email}`,
      })
      .then(response => {
        var resp = response.data;
        if (!resp.isError) {
          s(resp.message);
        } else {
          e(resp.message);
        }
      })
      .catch(error => {
        console.log(error);
        e('Failed to send request');
      });
    }
  },

  finishPasswordResset(key, password, s, e) {
    var bodyFormData = new FormData();
    bodyFormData.append('key', key);
    bodyFormData.append('password', password);

    instance({
      method: 'post',
      url: '/PasswordReset/Finish',
      data: bodyFormData,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  startPasswordResset(email, s, e) {
    var bodyFormData = new FormData();
    bodyFormData.append('email', email);
    if (email !== null) {
      instance({
        method: 'post',
        url: '/PasswordReset/SendEmail',
        data: bodyFormData,
      })
      .then(response => {
        var resp = response.data;
        if (!resp.isError) {
          s(resp.message);
        } else {
          e(resp.message);
        }
      })
      .catch(error => {
        console.log(error);
        e('Failed to send request');
      });
    }
  },

  getMetaData(type, s, e) {
    instance({
      method: 'options',
      url: `/Crud/${type}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.body);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  getNotes(type, s, e) {
    instance({
      method: 'get',
      url: `/Bucket/${type}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.body);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  crudRead(type, s, e, userId = -1, page = 1, pageSize = 100000000) {
    instance({
      method: 'get',
      url: `/Crud/${type}?page=${page}&pageSize=${pageSize}&userid=${userId}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.body);
      } else {
        e(resp.message);
        console.log('No Guid');
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  crudReadPaging(type, page, pageSize, search, searchFields, s, e, userId = -1) {
    instance({
      method: 'get',
      url: `/Crud/${type}?userid=${userId}&page=${page}&pageSize=${pageSize}&search=${search}&searchFields=${searchFields}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        e(resp.message);
      } else {
        s(resp);
        console.log('No Guid');
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  getUsers(s, e, page = 1, pageSize = 10, searchTerm = '') {
    instance({
      method: 'get',
      url: `/Users?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp);
      } else {
        e(resp.message);
        console.log('No Guid');
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  getPartsSupplyDealers(s, e, page = 1, pageSize = 10, searchTerm = '') {
    instance({
      method: 'get',
      url: `/PartsSupplyDealers?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp);
      } else {
        e(resp.message);
        console.log('No Guid');
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  getCompanies(s, e, page = 1, pageSize = 10, searchTerm = '') {
    instance({
      method: 'get',
      url: `/Companies?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp);
      } else {
        e(resp.message);
        console.log('No Guid');
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  CaseStatusReport(s, e, type) {
    instance({
      method: 'get',
      url: `/Report/${type}`,
    })
    .then(response => {
      s(response.data);
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  DownloadReport(s, e, type) {
    instance({
      method: 'get',
      url: `/Report/${type}?export=true`,
    })
    .then(response => {
      s(response.data);
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  CaseStatusReportPerCompany(s, e, type, comp) {
    instance({
      method: 'get',
      url: `/Report/${type}?q=${comp}`,
    })
    .then(response => {
      s(response.data);
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  crudReadSingle(type, id, s, e) {
    console.log('ID From Read', id);
    instance({
      method: 'get',
      url: `/Crud/${type}?id=${id}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.body);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  completeBusinessUserRegistration(
    guid,
    title,
    name,
    surname,
    email,
    password,
    number,
    role,
    company,
    s,
    e
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append('Title', title);
    bodyFormData.append('Name', name);
    bodyFormData.append('Surname', surname);
    bodyFormData.append('Email', email);
    bodyFormData.append('PhoneNumber', number);
    bodyFormData.append('Entity', role);
    bodyFormData.append('Company', company);
    bodyFormData.append('password', password);
    bodyFormData.append('guid', guid);

    instance({
      method: 'post',
      url: '/ApprovalRegistration/Complete',
      data: bodyFormData,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  CreateEntity(entity, s, e) {
    instance({
      method: 'post',
      url: '/Entity/Create',
      data: entity,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  CrudNew(type, bodyFormData, s, e) {
    instance({
      method: 'post',
      url: `/Crud/${type}`,
      data: bodyFormData,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  CrudUpdate(type, id, data, s, e) {
    instance({
      method: 'put',
      url: `/Crud/${type}?id=${id}`,
      data: data,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  GetPsdEmails(psdId, s, e) {
    instance({
      method: 'get',
      url: `/PartsSupplyDealers/${psdId}/user-emails`,
    })
    .then(response => {
      var resp = response.data;
      s(resp);
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  GetQuotes(status, page = 1, pageSize = 10, search = '', s, e) {
    instance({
      method: 'get',
      url: `/AudatexQuote/read/${status}?page=${page}&pageSize=${pageSize}&search=${search}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp);
      } else {
        e(resp.message);
        s(resp);
        console.log('No Guid');
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  GetQuote(id, s, e) {
    instance({
      method: 'get',
      url: `/AudatexQuote/item/${id}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp);
      } else {
        e(resp.message);
        s(resp);
        console.log('No Guid');
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  CaseClearParts(id, s, e) {
    instance({
      method: 'get',
      url: `/AudatexQuote/ClearParts/${id}`,
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  CaseUpdate(data, s, e) {
    instance({
      method: 'post',
      url: `/AudatexQuote/UpdateQuote/?id=${data.id}`,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  MakeClone(data, s, e) {
    instance({
      method: 'post',
      url: `/AudatexQuote/MakeClone/?id=${data.id}`,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.body);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  DeleteQuote(id, s, e) {
    instance({
      method: 'delete',
      url: `/AudatexQuote/DeleteQuote/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  login(email, password, s, e) {
    if (email !== null && password !== null) {
      var bodyFormData = new FormData();
      bodyFormData.append('Email', email);
      bodyFormData.append('Password', password);

      instance({
        method: 'post',
        url: '/Login',
        data: bodyFormData,
      })
      .then(response => {
        var resp = response.data;
        debugger
        if (!resp.isError) {
          localStorage.clear();
          window.localStorage.setItem('user', JSON.stringify(resp.body));
          window.localStorage.setItem('guid', resp.body?.apiKey);
          window.localStorage.setItem('token', resp.body.token);
          window.localStorage.setItem('logedin', true);
          s(resp.message);
        } else {
          e(resp.message);
        }
      })
      .catch(error => {
        console.log(error);
        e('Failed to send request');
      });
    }
  },

  isLogedin() {
    return window.localStorage.getItem('logedin') == 'true';
  },

  logout() {
    window.localStorage.setItem('user', null);
    window.localStorage.setItem('logedin', 'false');
  },

  sendMail(data, s, e) {
    instance({
      method: 'post',
      url: '/Notification/SendMail',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  }, 
  
  sendPSDEmails(data, s, e) {
    instance({
      method: 'post',
      url: '/Notification/SendPSDEmail',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },
  
  sendHARPMail(data, s, e) {
    instance({
      method: 'post',
      url: '/Notification/SendMailToAllAdmins',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },

  saveNewNote(data, s, e) {
    instance({
      method: 'post',
      url: '/Notes/CreateNote',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      var resp = response.data;
      if (!resp.isError) {
        s(resp.message);
      } else {
        e(resp.message);
      }
    })
    .catch(error => {
      console.log(error);
      e('Failed to send request');
    });
  },
};
