import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/LoginScreen.vue'


//
let isAuthenticated = false;
let isAdmin= false;


//old code
// function areYouAdmin() {
//     let roleType = JSON.parse(window.localStorage.getItem("user")).userType
//     if (roleType === 0) {
//         isAdmin = true
//         console.log("Admin is:",isAdmin,roleType)
//     } else {
//         isAdmin = false
//         console.log("Admin is:",isAdmin,roleType)
//     }
// }
// function areYouAuth() {
//     if (window.localStorage.getItem("guid")) {
//         isAuthenticated = true
//         console.log("Auth is:",isAuthenticated )
//     } else {
//         isAuthenticated = false
//         console.log("Auth is:",isAuthenticated )
//     }
//
// }


function iAmNotAdmin(to,from,next) {
    isAdmin = !!window.localStorage.getItem("user")

    if (isAdmin) {
        isAdmin = (JSON.parse(window.localStorage.getItem("user")).entity.entityLevel === 0);
        console.log("So You are An User..")
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('email') ){
            console.log("Logging You Off..")
            window.localStorage.clear()
            next(window.location.href);
        } else {
            if(isAdmin) {
                next();
            } else { console.log("... But not an admin"); next("/vehicles")}
        }
    } else {
        window.localStorage.clear()
        next("/");
        console.log("Not User")
    }
}

function yourLoggedIn(to,from,next){
    isAuthenticated = !!window.localStorage.getItem("guid");
    if (isAuthenticated) {
        next("/users" ,console.log("You Are Logged In")); // go to User if logged In
    } else {
        console.log("You Are Not Logged In");
        next(); // continue path

    }
}

function guardMyroute(to, from, next) {
    isAuthenticated = !!window.localStorage.getItem("guid");
    if (isAuthenticated) {
        next(); // allow entering route
    } else {
        window.localStorage.clear()
        next("/" , console.log("Not Authorised"), ); // go to '/login';
    }
}


const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        beforeEnter: yourLoggedIn,
    },
    {
        path: '/user-reg',
        name: 'User-reg',
        component: () => import(/* webpackChunkName: "user-reg" */ '../views/User-Reg.vue'),
        beforeEnter: yourLoggedIn,
    },
    {
        path: '/system-reports',
        name: 'System-Reports',
        component: () => import(/* webpackChunkName: "system-reports" */ '../views/System-Reports.vue'),
        beforeEnter: guardMyroute,
    },
    {
        path: '/system-uploads',
        name: 'System-Uploads',
        component: () => import(/* webpackChunkName: "system-uploads" */ '../views/System-Uploads.vue'),
        beforeEnter: guardMyroute,
    },
    {
        path: '/rep-dash',
        name: 'Rep-Dash',
        component: () => import(/* webpackChunkName: "rep-dash" */ '../views/Rep-Dash.vue'),
        beforeEnter: guardMyroute,
    },
    {
        path: '/reporting',
        name: 'Reporting',
        component: () => import(/* webpackChunkName: "reporting" */ '../views/ReportingScreen.vue'),
        beforeEnter: guardMyroute,
    },

    {
        path: '/entity-dash',
        name: 'Entity-Dash',
        component: () => import(/* webpackChunkName: "entity-dash" */ '../views/Entity-Dash.vue'),
        beforeEnter: guardMyroute,
    },
    {
        path: '/company-dash',
        name: 'Company-Dash',
        component: () => import(/* webpackChunkName: "company-dash" */ '../views/Company-Dash.vue'),
        beforeEnter: guardMyroute,
    },
    {
        path: '/psd-dash',
        name: 'PSD-Dash',
        component: () => import(/* webpackChunkName: "psd-dash" */ '../views/PSD-Dash.vue'),
        beforeEnter: guardMyroute,
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users-Dash.vue'),
        beforeEnter: iAmNotAdmin,

    },
    {
        path: '/ent-editor',
        name: 'Entity-Editor',
        component: () => import(/* webpackChunkName: "ent-editor" */ '../views/Ent-Editor.vue'),
        eforeEnter: iAmNotAdmin,

    },
    {
        path: '/ent-create',
        name: 'Entity-Create',
        component: () => import(/* webpackChunkName: "ent-create" */ '../views/Ent-Create.vue'),
        eforeEnter: iAmNotAdmin,

    },
    {
        path: '/create_user',
        name: 'Create User',
        component: () => import(/* webpackChunkName: "create_user" */ '../views/Create_User.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/create_company',
        name: 'Create Company',
        component: () => import(/* webpackChunkName: "create_company" */ '../views/Create_Company.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/edit-company',
        name: 'Edit-Company',
        component: () => import(/* webpackChunkName: "edit-company" */ '../views/Edit-Company.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/edit-psd',
        name: 'Edit-PSD',
        component: () => import(/* webpackChunkName: "edit-psd" */ '../views/Edit-PSD.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/create_psd',
        name: 'Create PSD',
        component: () => import(/* webpackChunkName: "create_psd" */ '../views/Create_PSD.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/edit_user',
        name: 'Edit User',
        component: () => import(/* webpackChunkName: "edit_user" */ '../views/Edit_User.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/vehicles',
        name: 'Vehicles',
        component: () => import(/* webpackChunkName: "vehicles" */ '../views/Vehicle-Dash.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/case-view',
        name: 'Case-View',
        component: () => import(/* webpackChunkName: "case-view" */ '../views/Case-View.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/order-view',
        name: 'Order-View',
        component: () => import(/* webpackChunkName: "order-view" */ '../views/orderview/Order-View.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/vehicles-details',
        name: 'Vehicles-Details',
        component: () => import(/* webpackChunkName: "vehicles-details" */ '../views/vehicle-details/Vehicle-Details.vue'),
        beforeEnter: guardMyroute,

    },

    {
        path: '/manual-quote',
        name: 'Manual-Quote',
        component: () => import(/* webpackChunkName: "manual-quote" */ '../views/Manual-Quote.vue'),
        beforeEnter: guardMyroute,

    },

    {
        path: '/vehicle-manual',
        name: 'Vehicle-Manual',
        component: () => import(/* webpackChunkName: "vehicle-manual" */ '../views/Vehicle-Manual.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/edit-chosen-vehicle',
        name: 'Edit-Chosen-Vehicle',
        component: () => import(/* webpackChunkName: "edit-chosen-vehicle" */ '../views/Edit-Chosen-Vehicle'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/quote',
        name: 'Quote',
        component: () => import(/* webpackChunkName: "quote" */ '../views/QuoteScreen.vue'),
        beforeEnter: guardMyroute,

    },
    {
        path: '/forgot-password',
        name: 'Forgot-Password',
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/Forgot-Password.vue'),
        beforeEnter: yourLoggedIn,
    },
    {
        path: '/password-reset',
        name: 'Password-Reset',
        component: () => import(/* webpackChunkName: "password-reset" */ '../views/Password-Reset.vue'),
        beforeEnter: yourLoggedIn,
    },
    {
        path: '/debug',
        name: 'Debug',
        component: () => import(/* webpackChunkName: "debug" */ '../views/DebugScreen.vue'),

    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach((to, from, next) => {
//
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (localStorage.getItem('jwt') !== null && localStorage.getItem('jwt') !== '') {
//             next()
//         } else {
//             if (to.name != 'Login') {
//                 next('')
//             } else {
//                 next()
//             }
//         }
//     } else {
//         next()
//     }
//
// })
export default router
